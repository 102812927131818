import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Apartment Number 8`}</strong></p>
    <p>{`I’ve always lived here, for what seems like forever. It was bought a long time ago by my mother and father back when they were just dating. A long time ago, they bought it out of excitement. A long time ago they were in love with the apartment on Sahaidachnoho Street. A long time ago they took an apartment which was given to three different families during the soviet times. It used to belong to a family of four, and a family of five and an old couple, before the wife passed. No one thought it was a good idea, other than my parents. They bought it and worked on it day and night and sometimes in between that, if such a time even exists.`}</p>
    <p>{`They tore down walls of pain and replaced them with new furniture. Old peeling paint was coming off the walls like a snakes’ old skin, but no worries, it was covered up with new fresh coats. Everything old was suddenly new again. They had completely changed the place, bringing light and hope back to a previously dank and sad bunch of rooms. They had taken a quilt made of thousands of odd little scraps of fabric into a new, warm, cozy blanket. Never before had it been like that. It was a new-old or an old-new place. A place that no one had ever seen before, but was oddly familiar with.`}</p>
    <p>{`Within those walls, somehow old and new at the same time, children had been raised, dogs had been rescued and fed and cared for. Billions of little memories occurred within those crumbling outer walls. Inside, the paint doesn’t peel, but outside it does. Outside, it’s exposed to rain, sun, hail, lightning, snow, and animals; bats that found cracks in the walls and make a home for themselves just above us. We never really noticed them, until they started making loud screeching noises that traveled through the ventilation. Almost like nails on a chalkboard. But inside, we were safe. Within ourselves, we are safe, free from the pain that the outside world thrusts upon us.`}</p>
    <p>{`Our windows let every ray of light in, they are big and clear. The front door looks so heavy it could be made of metal, but in reality, it’s firm, and rough, with a perfect swing when you open it. We have curtains on all the windows in bedrooms that are hard to pull shut. But they block out all the light so that even if it’s the middle of the day, you could think it was far past midnight. We also have the highest ceilings I’ve ever seen. You could stack me on top of my dad, and only then would we be able to touch it. The sheer height of them is a product of when the building was built.`}</p>
    <p>{`When you walk up to the entrance of our three-floor building, you are met with a giant burgundy metal door and once you get it open, the slams shut with a wave of sound that travels throughout the staircase. Some find the smell of the staircase weird, but I find it comforting. Like a musty library, or the smell of old books. Walking into our apartment, I don’t notice it has any smell at all, probably because I’m so used to it, that I’ve grown completely immune. But, people say it smells like fresh laundry and baked goods, like the way it would smell if your grandmother was baking cookies. Comforting, I’d say.`}</p>
    <p><strong parentName="p">{`Dead Places`}</strong></p>
    <p>{`Kyiv. A young, growing, lively city, full of new ideas springing up after the dark dull Soviet times. Just as spring suddenly grows from the dark depths of winter. Winter is a deadly season for many, with the bitter cold, constantly nipping at your cheeks, or livelihood. It’s a scary time, like when you walk into a cold basement and hear the quiet tapping of something unknown inching towards you. That’s a more metaphorical phrase, but it often feels that way for the little cafes surrounding us. Podil, of all places, has an infinite number of restaurants and cafes. I honestly couldn’t recollect all of them even if I wanted to, but some have more financial luck than others.`}</p>
    <p>{`There’s one venue that has never really been able to stay open. No matter what type of restaurant it is, the darkness of winter has always crushed business efforts. Of the dozens of businesses that have come and gone from that venue, I remember few, but regardless of its failures, it isn’t a great place to open up a restaurant. It’s situated on a calm street but has constant noise from the main road. A constant hum of automobiles and occasional rev of motorcycles are enough to drive away customers and kill a place, but somehow most establishments seem to stay open. Loud street sounds play over and over in my mind even when we’re somewhere completely different. Even louder ringing burns into my brain and won’t stop circling. Headaches even louder than that seem common too. So, that might explain some of it.`}</p>
    <p>{`We live right across the street from this strange place. It’s as deadly for restaurants as cats are formice. When you enter it, they’re a great big staircase outside that takes up half of the sidewalk. Those stairs are too skinny and steep and fragile for humans, yet they’re still used. Once you’re inside, it’s dank and dark and musty. Lit, only by the too-yellow light bulbs, decorated with a different mini-chandelier each time. Chairs, tables, and booths line the blank, boring, windowless walls, constantly empty, waiting to be filled with the people who never come. There was once a mediocre Turkish restaurant, then, a couple of long months later, another underwhelming rib place popped up, or that’s what my meat-eater brother and dad say. For the last couple of months, a breakfast place has managed to stay alive, but who knows when the darkness will finally creep over its existence too.`}</p>
    <p>{`I suppose that place was always dead. They come in dead, hopeless, boring, and leave just the same way. Maybe every time we see something new, our brain immediately thinks “Alive!”, rather than taking into consideration the death it came with. Last time something else opened up, my dad said to me;`}</p>
    <p>{`“Watch Naomi, those places are going to die soon”`}</p>
    <p>{`“Oh yeah sure, Dad”, I replied.`}</p>
    <p>{`But he was almost right. These places left dead, but maybe they came in that way too.`}</p>
    <p><strong parentName="p">{`Days and Weeks and Months and Seasons and Years`}</strong></p>
    <p>{`I was helping coach a basketball team when I first heard that we were going into lockdown. No in-person school, no restaurants, no movie theaters, no sports, no nothing. All I could do was stay inside. That made four months feel like forever. It’s been described and complained about too many times, but nothing ever brings justice to the feeling of not existing. It was reliving the same exact bad dream every - single - day. Previously, I didn’t know that seconds could freeze, and days would last for centuries or that months could go into a full-blown sprint, but faster than that. If you were to ask me what day it was, and I said the first thing that came to mind, I’d still say it’s March. Years seem to pass to slowly now. During quarantine, I asked my mom what day it was in April, and she tried to calmly tell me that it was already May. Time ever stopped. But somehow, I didn’t and still don’t feel time passing. I understand when my assignments are due, or when the holidays start, but somehow Mondays warp into Thursdays and Wednesdays are just an extra Saturday.`}</p>
    <p>{`I’d roll out of bed after snoozing an alarm with the same sound, maybe Jeremy’s soft humming or “scared” or the soft melodies of “Roslyn” by Bon Iver.`}</p>
    <p>{`I’d check my phone to see the same news. More new cases, more death, more destruction, but very little of it ever registered. It’s the literal version of things flying over my head. Like turning my back to something that I know is right there. I just don’t want to acknowledge it or choose not to. I was eerily calm through it all. Turning my back on the disaster that the world was, and still is. Despite the misery of it all, there’s a part of me that misses the unknown and elevated feeling. As if you were floating above the earth and watching everything happen in front of you, but you have no control over it whatsoever. Everything that you’re looking at is blurry and hard to see, but you still get it. Watching the world through a frosty glass window is difficult, but it’s still happening, you just don’t feel it. You’re completely numb. Unable to explain or comprehend any of it. Time is warped and gone and free at the same time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      